.BcBUC, .HGGS1 {
    display: flex;
    flex-direction: column;
}

.BcBUC {
    max-height: 18.4375rem;
}

.HGGS1 {
    align-items: flex-start;
    padding: 1rem 0.5rem;
}

.MWlQt {
    padding: 1rem;
}

.DG9jd {
    display: flex;
    justify-content: space-between;
    padding: 1.375rem var(--p-space-5);
}

.Ot7rf {
    border-left: var(--p-border-width-1) solid var(--p-divider);
    flex-basis: 25%;
    flex-grow: 1;
    padding-left: var(--p-space-4);
}

.Ot7rf:first-of-type {
    border-left: 0;
    padding-left: 0;
    padding-right: var(--p-space-4);
}

.Polaris-BlockStack-Filename {
    height: 100%;
}

.Polaris-BlockStack-Filename .Polaris-BlockStack {
    height: 100%;
}


.afaLZ {
    align-items: center;
    background: var(--p-color-bg-surface);
    border: calc(0.0625rem/var(--item-scale, 1)) solid var(--p-color-border-secondary);
    border-radius: calc(var(--p-border-radius-200)/var(--item-scale, 1));
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

.qT8wK {
    align-items: center;
    border-radius: calc(var(--p-border-radius-200) - 0.0625rem/var(--item-scale, 1));
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-mask-image: radial-gradient(#fff,#000);
    mask-image: radial-gradient(#fff,#000);
    overflow: hidden;
    transform: translateZ(0);
    width: 100%;
    height: 100%;
    z-index: 5;
}

.llVfq {
    display: block;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    z-index: 1;
}

.h200{
    height: 200px;
}

.p20{
    padding: 20px;
}

.w50{
    width: 48%;
}
